.social-media-card {
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 20px;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.social-media-card .icon {
  margin-right: 20px;
}

.social-media-card .details h3 {
  margin: 0;
  font-size: 1.5em;
}

.social-media-card-wrapper {
    
}