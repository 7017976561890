.notification-popup-header {
  padding-bottom: 20px;
}

.noti-icon-wrapper svg {
  font-family: 28px;
}

.noti-btns {
  padding-bottom: 10px;
}

.noti-btn {
  color: var(--black);
}

.no-notification p {
  padding-top: 30px;
}

.notification-item-wrapper {
  border: 1px solid var(--LGrey);
  background-color: rgba(150, 129, 249, 0.1);
  padding: 10px;
  border-radius: 10px;
  margin-bottom: 10px;
  column-gap: 15px;
}

.noti-item-icon-img-wrapper svg {
  font-size: 20px;
}

.main-noti-content h6 {
  font-size: 14px;
}

.main-noti-content p {
  font-size: 12px;
  font-weight: 300;
}

.notification-time p {
  font-size: 12px;
  color: var(--Grey);
  font-weight: 500;
}

.noti-item-icon-img-wrapper {
  /* padding: 10px;
  width: 50px;
  height: 50px;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%; */
  flex-shrink: 0;
}

.notification-icon {
  padding: 10px;
  width: 50px;
  height: 50px;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}

.noti-btns button {
  background: none;
  border: none;
}

.notification-content-wrapper {
  flex-grow: 1;
  /* Take up remaining space */
  min-width: 0;
  /* Prevent overflow issues */
  overflow: hidden;
  /* Hide overflowing content */
  text-overflow: ellipsis;
}

.remove-icon-wrapper {
  text-align: center;
}

.notification-icon {
  flex-shrink: 0;
  /* Prevent shrinking */
}