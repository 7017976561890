.filters-btns-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media (max-width: 767px) {
  .employees-wrapper {
    margin-bottom: 50px;
  }

  .emp-filter-btn-wrapper {
    flex-wrap: wrap;
  }

  .filters-btns-container {
    flex-wrap: wrap;
  }

  .add-emp-filter-btns-conatiner {
    padding: unset;
  }
}
