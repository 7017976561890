.mp-project-name {
  border: 1px solid var(--LGrey);
  box-shadow: 10px 10px 15px rgba(68, 97, 242, 0.1);
  padding: 10px 20px;
  border-radius: 10px;
  text-transform: capitalize;
}

.mp-project-name-wrapper {
  /* padding-bottom: 30px; */
  background-color: var(--white);
}

.project-tasks-management {
  width: 100%;
  max-height: 60vh;
  overflow-y: scroll;
  position: relative;
  border-radius: 10px;
}

.tt-col-width {
  width: 80%;
}

.action-col td:last-child {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
}

.breadcrumb-pg-header-container {
  position: sticky;
  top: 6%;
  z-index: 2;
}

.customTt thead {
  /* position: fixed; */
}

.dropdown-container {
  position: relative;
  display: inline-block;
}

.dropdown-toggle {
  padding: 10px 15px;
  background-color: #007bff;
  color: white;
  cursor: pointer;
  border-radius: 4px;
  user-select: none;
}

.dropdown-toggle:hover {
  background-color: #0056b3;
}

.dropdown-list {
  padding: 10px;
  position: absolute;
  left: 0;
  top: 100%;
  width: 100%;
  max-height: 200px;
  overflow-y: auto;
  background-color: var(--white);
  border: 1px solid #ddd;
  border-radius: 4px;
  z-index: 1000;
  top: 100%;
}

.dropdown-item {
  padding-bottom: 10px;
  cursor: pointer;
  font-size: 12px;
  white-space: break-spaces;
  word-break: break-word;
}

.dropdown-item:hover {
  background-color: #f1f1f1;
}

.manage-project-dropdown {
  position: relative;
  width: fit-content;
  border-radius: 10px;
}

.new-icon-btn {
  padding: 5px 10px !important;
  border-radius: 10px;
  font-size: 12px;
  display: flex;
  align-items: center;
  font-weight: 600;
}

.new-icon-btn svg {
  margin-top: 0px;
  font-size: 20px;
}

.manage-project-dropdown button {
  width: 100%;
  display: flex;
  align-items: center;
  padding: 10px 20px;
  border-radius: 10px;
  text-transform: uppercase;
  justify-content: space-around;
  column-gap: 20px;
}

.manage-project-btns-wrapper {
  display: flex;
  column-gap: 10px;
  justify-content: end;
}

.sort-by {
  font-size: 12px;
  padding-bottom: 2px;
  text-align: end;
}

.att-filters-wrapper .manage-project-dropdown .btn-edit {
  padding: 5px 10px !important;
  font-size: 12px !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: 10px;
  font-weight: 600;
  text-transform: capitalize;
}

@media (max-width: 767px) {
  .manage-project-dropdown .btn-edit {
    margin-left: unset;
  }

  .action-btn-wrapper {
    column-gap: 10px;
  }

  .manage-project-dropdown button {
    padding: 5px;
    font-size: 12px;
  }

  .project-tasks-management {
    margin: unset;
    overflow-x: scroll;
  }

  .manage-project-btns-wrapper {
    justify-content: flex-start;
    flex-wrap: wrap;
    column-gap: 10px;
  }

  .sort-by {
    text-align: start;
    padding-bottom: 5px;
  }

  .mp-sort-by-txt {
    margin-top: 10px !important;
  }

  .manage-dropdown {
    margin-bottom: 10px;
  }
}

::-webkit-scrollbar {
  display: none;
}
