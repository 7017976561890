.leave-wfh-card {
  padding: 20px;
  background-color: var(--LViolet);
  border-radius: 10px;
  border: unset;
}

.leave-wfh-card-icon svg {
  font-size: 40px;
}

.leave-wfh-count p {
  font-size: 45px;
  font-weight: 700;
  margin-bottom: 0px;
  padding: 5px 0px;
}

.lwc-green {
  background-color: var(--lGreen);
}

.plr-orange {
  background-color: var(--LOrange);
}

.leave-wfh-text p {
  font-size: 16px;
  font-weight: 300;
  margin-bottom: 0px;
  line-height: 1;
}

.leave-apply-btn {
  background-color: var(--violet) !important;
}

.approval-btns {
  display: flex;
  gap: 10px;
  justify-content: center;
}

.approve-btn {
  background-color: #8bc34a;
  color: white;
  border: none;
  padding: 5px 10px;
  border-radius: 4px;
  cursor: pointer;
}

.reject-btn {
  background-color: #e57373;
  color: white;
  border: none;
  padding: 5px 10px;
  border-radius: 4px;
  cursor: pointer;
}

.approve-btn:hover,
.reject-btn:hover {
  opacity: 0.8;
}

@media only screen and (max-width: 767px) {
  .leave-wfh-card {
    padding: unset;
    margin-bottom: 10px;
  }

  .leave-wfh-text p {
    font-size: 14px;
    line-height: 1.3;
  }

  .leave-apply-btn-container {
    margin-bottom: 10px;
  }

  .leave-wfh-card .card-body {
    padding: 20px;
  }
}
