.popup-box .deletePopupAlert{
    border-top: 10px solid var(--purple);
}


.deletePopupAlert{
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    align-items: center;
    width: 300px;
    margin: 0 auto;
    margin-top: -100px;
    padding: 15px;
    box-shadow: var(--boxShadow);
    border-radius: 5px;
    /* position: relative;
    top: 50%;
    left: 50%; */
    /* transform: translate(-50%, -50%); */
    border-top: 10px solid var(--violet);
    background: #fff;
}

.deletePopupAlert .deleteBtnsWrapper{
    display: flex;
    column-gap: 20px;
}

.deletePopupAlertWrapper{
    height: 100vh;
    width: 100%;
    position: fixed;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9;
    background: #00000059;
    left: 0;
}