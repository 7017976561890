.mini-loader-section {
  padding: 10px;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ccc;
  cursor: pointer;
  transition: 0.3s linear;
  width: 100%;
  height: 100vh;
  background: #0000008a;
  top: 0;
  left: 0;
  z-index: 1;
}

/* .mini-loader-inner{
  width: 100%;
  height: 100%;
  position: fixed;
  background: none;
  display: flex;
  align-items: center;
  justify-content: center;
  
} */

@media (max-width: 768px) {
  .mini-loader-section {
    width: 50%;
  }
}

@media (max-width: 480px) {
  .mini-loader-section {
    width: 100%;
  }
}

.loader-1 {
  width: 48px;
  height: 48px;
  border: 5px solid #fff;
  border-bottom-color: var(--violet);
  border-radius: 50%;
  display: inline-block;
  -webkit-animation: rotation 1s linear infinite;
  animation: rotation 1s linear infinite;
}

/* keyFrames */
@-webkit-keyframes rotation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}