.color-btn {
  height: 15px;
  width: 15px;
  border-radius: 50%;
}

.note-editor-title,
.note-main-body {
  background-color: #eeeeee;
  border: unset;
  border-radius: 10px 10px 0px 0px;
  padding: 10px 20px;
  font-size: 24px;
  font-weight: 300;
}

.note-editor-title::placeholder {
  color: var(--Grey);
}

.note-main-body {
  font-size: 16px;
  border-radius: 0px 0px 10px 10px;
  border-top: 1px solid var(--black);
  min-height: 50vh;
}

@media (max-width: 767px) {
  .note-main-body {
    margin-bottom: 20px;
  }
}
