.tickets-data {
  width: 100%;
}

.tickets-data tr th {
  text-transform: uppercase;
  padding: 10px;
  font-size: 14px;
  font-weight: 500;
}

.tickets-data tr td {
  padding: 10px 0px;
  color: var(--white);
  text-align: center;
  font-size: 14px;
}

.tickets-data tr th {
  color: var(--white);
  text-align: center;
}

.tickets-data tr:nth-child(even) {
  background-color: var(--DBlue);
}

.tickets-data thead tr {
  background-color: var(--DBlue) !important;
}

.tickets-data tr:nth-child(odd) {
  background-color: var(--LBlue);
}

.tickets-data thead tr:first-child th:first-child {
  border-top-left-radius: 10px;
}

.tickets-data thead tr:first-child th:last-child {
  border-top-right-radius: 10px;
}

.tickets-data tbody tr:last-child td:first-child {
  border-bottom-left-radius: 10px;
}

.tickets-data tbody tr:last-child td:last-child {
  border-bottom-right-radius: 10px;
}

@media (max-width: 767px) {
  .tickets-table-wrapper {
    overflow-x: scroll;
  }

  .tickets-data tr th,
  .tickets-data tr td {
    font-size: 10px;
    padding: 5px;
  }

  .emp-overview-tt-wrapper {
    margin-bottom: 50px;
  }
}
