.announcement-bar-wrapper {
  padding: 5px 10px 0px;
  background-color: var(--Pink);
}

.announcement-txt p {
  color: var(--white);
}

@media (max-width: 767px) {
  .announcement-bar-wrapper {
    padding: 0px 5px;
  }
  .announcement-txt marquee {
    margin-top: 5px;
    font-size: 12px;
  }
}
