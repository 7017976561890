.pc-title h5 {
  color: var(--white);
  text-transform: capitalize;
  font-weight: 400;
}

.pc-options svg {
  color: var(--white);
  font-size: 20px;
}

.all-projects-wrapper {
  padding: 20px;
}

.project {
  padding: 10px;
  background-color: var(--white);
  border-radius: 10px;
}

.pc-title-options-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
}

.project-card {
  border: unset;
  border-radius: 10px;
  overflow: hidden;
}

.project-progress {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.pp-bar {
  width: 80%;
  background-color: unset;
  border: 1px solid var(--Red);
  --bs-progress-bar-bg: var(--Red);
  height: 0.5rem;
}

.project-priority {
  width: 30%;
  text-align: center;
  border-radius: 25px;
}

.project-priority p {
  font-size: 14px;
  font-weight: 600;
}

.project-name h5,
.delivery-date h6,
.project-priority p {
  text-transform: capitalize;
}

.priority-manage-project-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 10px;
}

.manage-project-btn button {
  padding: 0px 15px !important;
  font-size: 14px;
}

.project-card .pc-options svg {
  position: absolute;
  right: 0px;
  top: 50%;
  transform: translateY(-50%);
  color: #fff;
  cursor: pointer;
}

.pc-options .dropdown-optio-wrapper {
  right: -20px;
  top: 23px;
}

.pc-options .dropdown-optio-wrapper ul li {
  text-align: center;
}

@media (max-width: 767px) {
  .project-card {
    margin-bottom: 10px !important;
  }
  .pc-title h5 {
    font-size: 14px;
  }

  .project-card .pc-options svg {
    right: 0px;
    font-size: 14px;
  }

  .pc-title-options-wrapper {
    padding: 5px 15px;
  }

  .all-projects-wrapper {
    padding: 10px;
  }

  .project-name h5 {
    font-size: 14px;
  }

  .delivery-date h6 {
    font-size: 14px;
  }

  .project-priority p,
  .manage-project-btn button {
    font-size: 12px !important;
  }
}
