.services-cards-wrapper {
  
}

.service-btn {
  padding: 30px 10px;
  width: 100%;
  margin-top: 20px;
  text-transform: capitalize;
  border: unset;
  font-size: 18px;
  font-weight: 600;
}

.service-btn:hover {
  background-color: var(--violet);
}

.service-btn.disabled {
  background-color: var(--LGrey);
  color: var(--black);
}

.wit-btn {
  background-color: var(--Green);
}

.pm-btn {
  background-color: var(--SkyBlue);
}

.bm-btn {
  background-color: var(--Orange);
}

.graphics-btn {
  background-color: var(--Red);
}

.content-btn {
  background-color: var(--Pink);
}
