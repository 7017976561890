.online-meeting-wrapper p {
  padding-top: 10px;
  font-weight: 500;
}

.meeting-options {
  border-radius: 50%;
  height: 150px;
  width: 150px;
  margin: 0 auto;
  padding: 45px;
}

.meeting-options svg {
  font-size: 60px;
  color: var(--white);
}

.new-meeting-icon {
  background-color: var(--Green);
}

.join-meeting-icon {
  background-color: var(--Orange);
}

.cancelled-meetings-icon {
  background-color: var(--Red);
}

@media (max-width: 767px) {
  .meeting-options {
    height: 100px;
    width: 100px;
  }

  .meeting-options svg {
    font-size: 40px;
  }

  .meeting-options {
    padding: 30px;
  }
}
