.token-expire-container {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  position: fixed;
  background: #0000003d;
  overflow: hidden;
  z-index: 9999999999 !important;
  flex-direction: column;
  align-items: center;
}

.expired-popup {
  box-shadow: 0px 0px 31px -4px #ccc;
  row-gap: 20px;
  background: #fff;
  padding: 50px;
  text-align: center;
  display: flex;
  height: 200px;
  width: 40%;
  flex-direction: column;
  justify-content: center;
  border-radius: 30px;
}

.expired-popup h1 {
  font-size: 25px;
}

.expired-popup .uniBtnBlue {
  padding: 10px 30px;
  border-radius: 25px;
}

/* .expired-popup{
    padding: 50px;
    text-align: center;
    display: inline-block;
} */

@media (max-width: 767px) {
  .expired-popup h1 {
    font-size: 20px;
  }

  .expired-popup {
    row-gap: 5px;
    padding: 10px 30px;
    height: 140px;
    width: 80%;
    border-radius: 10px;
  }
}
