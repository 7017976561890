.reports-wrapper {
  
}

.report-card-wrapper {
  padding: 30px 10px;
  background-color: var(--violet);
  border-radius: 10px;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.report-card-wrapper p {
  color: var(--white);
  font-weight: 600;
  margin-bottom: 0px;
  text-transform: capitalize;
}

.report-card-wrapper svg {
  color: var(--white);
}
