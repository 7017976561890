.sign-in-pg {
  padding: 100px 0px;
}

.signin-head {
  padding-bottom: 20px;
}

.signin-head h2 {
  font-size: 60px;
  font-weight: 300;
}

.signin-pg-txt {
  margin-bottom: 50px;
}

.signin-pg-txt p {
  font-weight: 200;
  letter-spacing: 0.5px;
}

.signin-pg-img img {
  height: 250px;
  width: auto;
}

.signin-form-head h2 {
  color: var(--violet);
  font-weight: 100;
}

.signin-form-wrapper input {
  height: 50px;
  width: 100%;
  padding: 5px 10px;
  margin-bottom: 15px;
  border: 1px solid var(--LGrey);
  box-shadow: 10px 10px 15px rgba(68, 97, 242, 0.1);
  border-radius: 10px;
}

.signin-form-wrapper input::placeholder {
  color: var(--LGrey);
  font-size: 14px;
}

.signin-form-btn {
  width: 100%;
  height: 50px;
  background-color: var(--violet);
  border: unset;
  margin-top: 5px;
  border-radius: 10px;
}

.reg-change-pwd {
  display: flex;
  justify-content: space-between;
  padding-top: 10px;
}

.reg-change-pwd p {
  text-decoration: underline;
}

.reg-change-pwd p a {
  color: var(--black);
}

.si-footer {
  margin-top: 3%;
}

.link-button {
  background: none;
  border: none;
  cursor: auto !important;
  text-decoration: underline !important;
  padding: unset;
  color: var(--black);
}

.sign-in-pg {
  padding: 40px 0;
  overflow: hidden;
}

/* new desgin  */
.sign-in-wrapper {
  /* margin-bottom: 100px; */
}

.sign-in-pg-img img {
  height: auto;
  width: 90%;
  margin: unset;
}

.org-logo img {
  height: 40px;
  width: auto;
}

.signin-head p {
  font-weight: 600;
}

.signin-form-head h2 {
  padding-bottom: 20px;
}

.signin-wrapper {
  padding: 0 10%;
}

@media (max-width: 767px) {
  .sign-in-wrapper {
    margin-bottom: 50px;
  }

  .signin-form-wrapper input {
    margin-bottom: unset;
  }

  .org-logo {
    margin-top: 20px;
    text-align: center;
  }
}
