.pm-status-table-container section .date-filter-wrapper {
  display: none;
}

.leads-table-container {
  padding: 3% 0%;
  /* margin-bottom: 70px; */
}

.heading h2 {
  padding: 0 3%;
}
