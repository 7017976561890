.ov-card-wrapper {
  border: unset;
  text-align: center;
  height: 120px;
  margin-bottom: 20px;
}

.ovc-title {
  color: var(--white);
  font-size: 45px;
  font-weight: 400;
  font-family: var(--ff);
}

.ovc-text {
  color: var(--white);
  text-transform: uppercase;
}

@media only screen and (max-width: 767px) {
  .ov-card-wrapper {
    height: 90px;
    margin-bottom: 10px;
  }

  .ov-card-wrapper .card-body {
    padding: 10px;
  }

  .ov-card-wrapper .card-body .card-text {
    font-size: 12px;
  }
}
