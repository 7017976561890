@media (max-width: 767px) {
  .attendance-record-table .sm-kpi-table-wrapper {
    overflow-x: scroll;
  }

  .attendance-record-table .sm-kpi-table-wrapper .kpi-table tr td {
    padding: 10px;
    font-size: 12px;
  }
}
