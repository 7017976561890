.tab-wrapper {
  width: 100%;
  margin-top: 50px;
}

.tabs-btn-wrapper {
  text-align: center;
  display: flex;
  justify-content: center;
}

.tabs-btn-wrapper li {
  width: 20%;
  border: unset;
  padding: 10px 20px;
  list-style-type: none;
  background-color: #dddddd4d;
}

.tabs-btn-wrapper .active {
  background-color: var(--violet);
  color: var(--white);
}

.tabs-btn-wrapper li:first-child {
  border-radius: 10px 0 0 10px;
}

.tabs-btn-wrapper li:last-child {
  border-radius: 0 10px 10px 0px;
}

.tab-content-container {
  padding-top: 20px;
}

.filter-container,
.date-picker-container {
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 40px;
}

.sort-filters,
.from-date-picker-wrapper,
.to-date-picker {
  display: flex;
  align-items: center;
  column-gap: 10px;
}

.form-main-container .cal-form-dropdown.interval-selector {
  height: 40px;
  padding: 0px;
  margin-bottom: unset;
  border: unset;
}

.form-main-container .cal-form-dropdown.interval-selector button {
  border: 1px solid var(--LGrey);
}

.sort-filters.form-main-container {
  padding-top: unset;
}

.form-main-container .from-date-picker-wrapper input,
.form-main-container .to-date-picker input,
.form-main-container .cal-form-dropdown.interval-selector button {
  margin-bottom: unset !important;
  height: 40px;
  width: 100%;
  padding: 10px;
  font-size: 14px;
  text-transform: uppercase;
  border-radius: 10px;
}

.filter-container.form-main-container {
  padding-top: 30px;
}

.kpi-selector button {
  background-color: transparent;
  color: var(--black);
  border: 1px solid var(--LGrey);
}
