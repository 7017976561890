.main-header {
  box-shadow: 10px 10px 15px rgba(68, 97, 242, 0.1);
  border-radius: 0px 0px 15px 15px;
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 9999;
  background-color: #fff;
}

.header-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
}

.header-logo img {
  height: 40px;
  width: auto;
}

.help a {
  color: var(--LBlue);
  font-weight: 300;
}

@media (max-width: 767px) {
  .header-logo img {
    height: 30px;
  }

  .main-header {
    position: fixed;
  }

  .main-container-wrapper {
    margin-top: 14%;
  }
}
