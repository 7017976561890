.sm-kpi-table-wrapper table {
  width: 100%;
}

.date-filter-wrapper {
  padding: 0px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  column-gap: 10px;
}

.date-filter-wrapper label {
  font-weight: 500;
  color: var(--DBlue);
}

.date-filter-wrapper .dropdown button {
  margin-left: 10px;
  background-color: var(--Green);
  border: unset;
  padding: 5px 20px;
  border-radius: 10px;
  text-align: center;
  color: var(--white);
}

.date-filter-wrapper option {
  background-color: var(--DBlue);
  color: var(--white);
}

.kpi-table {
  width: 100%;
  margin-top: 10px;
}

.kpi-table tr th {
  text-transform: uppercase;
  padding: 10px;
}

.kpi-table tr td {
  padding: 10px;
  color: var(--white);
  text-align: center;
  font-size: 14px;
}

.kpi-table tr th {
  color: var(--white);
  text-align: center;
  font-size: 14px;
  font-weight: 500;
}

.kpi-table tr:nth-child(even) {
  /* background-color: var(--DBlue); */
  background-color: var(--LBlue);
}

.kpi-table thead tr {
  background-color: var(--DBlue) !important;
}

.kpi-table tr:nth-child(odd) {
  background-color: var(--LBlue);
}

.kpi-table thead tr:first-child th:first-child {
  border-top-left-radius: 10px;
}

.kpi-table thead tr:first-child th:last-child {
  border-top-right-radius: 10px;
}

.kpi-table tbody tr:last-child td:first-child {
  border-bottom-left-radius: 10px;
}

.kpi-table tbody tr:last-child td:last-child {
  border-bottom-right-radius: 10px;
}

.kpi-table tr td:first-child {
  /* padding: 0px 5px; */
}

.leave-action-btn {
  color: #fff;
  padding: 5px 10px;
  border-radius: 10px;
  border: none;
  margin-right: 10px;
}

.leave-action-btn.approve-btn {
  background: #A2CD48;
}

.leave-action-btn.reject-btn {
  background: #EE534F;
}

tr.leave-inner-data-row{
  /* border-bottom: 1px solid #fff; */
}

tr.leave-inner-data-row td{
  border: none;
}

@media (max-width: 767px) {
  .kpi-table tr th {
    font-size: 10px;
    padding: 5px;
  }
}

.pagination-controls {
  display: flex;
  align-items: center;
  /* width: 100%; */
  justify-content: center;
  column-gap: 10px;
  margin-top: 20px;
}


.kpi-table {
  border-collapse: collapse;
  width: 100%;
}

.kpi-table th,
.kpi-table td {
  border: 1px solid #ddd;
  text-align: center;
  padding: 8px;
}

.kpi-table .nested-header th {
  font-weight: normal;
  border: none;
}

.kpi-table .nested-cell td {
  padding: 4px;
}

.check-icon {
  color: green;
}

.cross-icon {
  color: red;
}

.kpi-table .nested-cell th,
.kpi-table .nested-cell td {
  border: none;
}

.nested-cell tr td {
  padding: 0;
}

/* .leave-inner-data-row td {
  width: 40%;
  /* border: none; */
/* }  */

.leave-inner-data-row {
  display: flex;
}

.leave-inner-data-row td {
  flex: 1;
  text-align: center;
  padding: 8px;
  /* border-right: 1px solid #ddd; */
  border-radius: 0 !important;
}


@media (max-width: 767px) {
  .kpi-table tr td {
    font-size: 10px;
  }
}