.popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.popup-content {
    background: #fff;
    padding: 20px;
    border-radius: 8px;
    width: 500px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

.permissions-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
}

.permissions-table th,
.permissions-table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: center;
}

.permissions-table th {
    background-color: #f4f4f4;
    font-weight: bold;
}

.permissions-table td:first-child {
    text-align: left;
}

.form-group {
    margin-bottom: 20px;
}

.form-actions {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
}

.btn-submit {
    background-color: #4CAF50;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.btn-cancel {
    background-color: #f44336;
}

.permissions-table th, .permissions-table td {
    text-align: center;
    padding: 8px;
}

.permissions-table th {
    background-color: #f4f4f4;
    font-weight: bold;
}

.permissions-table td:first-child, .permissions-table th:first-child {
    text-align: left;
}


.popup-content .role-field-wrapper{
    display: flex;
    flex-direction: column;
}

.popup-content .role-field-wrapper input{
    border: none;
    border-bottom: 1px solid #000;
    padding: 5px;
}