/* Static breadcrumb  */
.static-breadcrumb-wrapper {
  background-color: var(--LGrey);
  padding: 2% 3%;
}

@media (max-width: 767px) {
  .static-breadcrumb-wrapper {
    margin-bottom: 20px;
  }

  .sb-content-wrapper h2 {
    font-size: 20px;
    margin-bottom: 5px;
  }

  .sb-content-wrapper p {
    font-size: 14px;
  }
}
