.add-note-btn {
  font-size: 22px;
  padding: 10px 30px;
}

.add-note-btn svg {
  font-size: 24px;
}

.nc-options button svg {
  position: absolute;
  right: -5px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
}

.nc-dropdown-options {
  display: flex;
  flex-direction: column;
  position: absolute;
  right: 0px;
  top: 100%;
  background: #fff;
  box-shadow: 0px 0px 25px -5px #ccc;
  right: -20px;
  top: 23px;
}

.notes-top-btns button svg {
  font-size: 18px;
}

.notes-card {
  border-radius: 10px;
}

.note-card-title-btn-wrapper {
  padding: 5px 20px;
  border-bottom: 1px solid var(--white);
}

.note-title {
  font-size: 18px;
  font-weight: 500;
}

.nc-dots {
  font-weight: 600;
}

.note-date-btn-wrapper {
  padding: 10px 20px;
}

.note-text {
  color: var(--Grey);
}

.note-date {
  font-size: 12px;
  font-weight: 700;
  color: var(--Grey);
}

.note-edit-btn button {
  padding: unset;
}

.notes-top-btns button,
.note-edit-btn button {
  background-color: transparent;
}
