.Sidebar-header {
  display: flex;
  align-items: center;
  padding: 10px;
}

.collapse-icon svg {
  color: white;
  font-size: 20px;
}

.sidebar-header-menu {
  background-color: var(--NBlue);
  padding-bottom: 130px;
}

.greeting-name h5 {
  color: var(--white);
}

.custom-scrollbar ul li a span {
  color: var(--white);
}

.custom-sidebar-db {
  padding: 5px 15px;
}

.custom-sidebar-db ul li {
  background-color: var(--DBlue);
  margin-bottom: 10px;
  border-radius: 5px;
}

.custom-sidebar-db ul li a {
  padding: 5px 20px;
  height: auto !important;
}

.custom-sidebar-db ul li a .ps-menu-label {
  font-size: 14px;
}

.custom-scrollbar ul li a .ps-menu-icon {
  background-color: var(--violet);
  width: 20px;
  min-width: 20px;
  height: 20px;
  border-radius: 5px;
}

.custom-sidebar-db ul li a:hover {
  background-color: var(--violet) !important;
  border-radius: 5px;
}

.custom-scrollbar ul li a:hover .ps-menu-icon {
  background-color: var(--white) !important;
  color: var(--violet) !important;
}

.sidebar-inner-wrapper {
  position: fixed !important;
  overflow: scroll;
  height: 100%;
}

.collapsePadding {
  padding: 5px !important;
}

.css-dip3t8 {
  background-color: var(--DBlue) !important;
}

.css-1wvake5.ps-collapsed {
  border: unset;
}

.sidebar-main-wrapper .css-1wvake5.ps-collapsed {
  width: 60px;
  min-width: 60px;
}

@media (max-width: 767px) {
  .sidebar-main-wrapper .css-1wvake5.ps-collapsed {
    width: 60px;
    min-width: 60px;
  }

  .custom-sidebar-db ul li a .ps-menu-label {
    display: none;
  }

  .css-1t8x7v1 > .ps-menu-button {
    justify-content: center;
    padding: 5px 0px !important;
  }

  .custom-scrollbar ul li a .ps-menu-icon {
    margin: unset;
  }
}

.sidebar-inner-wrapper > div {
  background-color: var(--NBlue);
}