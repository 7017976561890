.manage-dropdown {
  position: relative;
  width: fit-content;
  border-radius: 10px;
}

.dropdown-list {
  padding: 0;
  position: absolute;
  /* top: 100%; */
  left: 0;
  width: 100%;
  max-height: 200px;
  overflow-y: auto;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 4px;
  z-index: 1000;
}

.dropdown-item {
  padding-bottom: 10px;
  cursor: pointer;
}

.dropdown-item {
  display: block;
  width: 100%;
  clear: both;
  font-weight: 400;
  color: var(--bs-dropdown-link-color);
  text-align: inherit;
  text-decoration: none;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
  border-radius: var(--bs-dropdown-item-border-radius, 0);
}

.new-small-btn {
  padding: 5px 10px !important;
  font-size: 12px !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: 10px;
  font-weight: 600;
}

@media (max-width: 767px) {
  .new-small-btn {
    padding: 5px !important;
    column-gap: 5px;
    font-weight: 400;
  }

  .dropdown-item {
    padding-bottom: 5px;
    font-size: 10px;
    word-break: break-word;
  }

  .add-emp-btn-wrapper {
    text-align: end;
  }
}
