.form-main-container label {
  text-transform: capitalize;
}
.form-main-container {
  padding-top: 20px;
}

.form-main-container label {
  font-size: 16px;
  font-weight: 400;
}

.form-main-container input,
.form-main-container .cal-form-dropdown,
.form-main-container textarea,
.tasks-table tbody tr td input,
.tasks-table tbody tr td select,
.cal-form-dropdown {
  height: 45px;
  max-height: fit-content;
  width: 100%;
  padding: 10px;
  border: 1px solid var(--LGrey);
  box-shadow: 10px 10px 15px rgba(68, 97, 242, 0.1);
  border-radius: 10px;
  margin-bottom: 10px;
}

.form-main-container textarea {
  height: auto !important;
}

.save-plan-btn {
  margin-top: 25px;
  width: 20%;
}

.form-main-container .cal-form-dropdown button {
  width: 100%;
  height: 50px;
  background-color: transparent;
  font-size: 18px;
  color: var(--black);
}

.form-main-container .cal-form-dropdown .cal-dropdown-options {
  width: 100%;
}

.plan-data-edit-btn-container {
  display: flex;
  justify-content: space-between;
}

.plan-form-error {
  text-transform: capitalize;
  color: var(--Red);
  margin-bottom: 15px;
}

.calendar-tile-content {
  width: 100%;
}

.plan-brand-name {
  background-color: var(--LViolet);
  border-left: 5px solid var(--violet);
  margin: 2px 0px;
}

.plan-brand-name p {
  font-size: 12px;
  color: var(--violet);
  margin: unset;
  padding: unset;
}

.leave-form-type > div {
  padding: 10px 30px;
  background-color: var(--Orange);
  border-radius: 10px;
  color: var(--white);
}

.select-options-wrapper {
  position: relative;
}

.select-options-inner-wrapper {
  width: 100%;
  height: 0%;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease;
  background-color: var(--NBlue);
}

.select-options-wrapper ul {
  margin: 0;
  padding: 0;
  width: 94%;
  height: 100%;
  position: absolute;
  overflow: scroll;
}

.select-options-wrapper ul::-webkit-scrollbar-thumb {
  background-color: var(--NBlue);
}

.select-options-wrapper ul li {
  list-style: none;
  text-align: left;
  display: flex;
  align-items: center;
  justify-content: start;
  column-gap: 10px;
  color: var(--black);
  padding: 0 5px;
}

.select-options-wrapper ul li input {
  display: inline;
  width: initial;
  margin: 0;
  height: auto;
}

.select-options-inner-wrapper.visible {
  height: 170px;
  opacity: 1;
  visibility: visible;
  transition: all 0.3s ease;
  background-color: #f9f9f9;
  min-width: fit-content;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  padding: 10px;
  margin-top: 10px;
  font-size: 14px;
}

@media (max-width: 767px) {
  .tasks-table tbody tr td select {
    padding: 5px;
  }

  .form-main-container {
    padding-top: unset;
  }
}
