.planning-form {
  padding-top: 20px;
}

.planning-form label {
  font-size: 16px;
  font-weight: 400;
}

.planning-form input,
.planning-form .cal-form-dropdown button {
  min-height: 50px;
  max-height: fit-content;
  width: 100%;
  padding: 10px;
  border: 1px solid var(--LGrey);
  box-shadow: 10px 10px 15px rgba(68, 97, 242, 0.1);
  border-radius: 10px;
  margin-bottom: 15px;
}

.save-plan-btn {
  margin-top: 25px;
  width: 20%;
}

.planning-form .cal-form-dropdown button {
  width: 100%;
  height: 50px;
  background-color: transparent;
  font-size: 18px;
  color: var(--black);
}

.planning-form .cal-form-dropdown .cal-dropdown-options {
  width: 100%;
}

.plan-data-edit-btn-container {
  display: flex;
  justify-content: space-between;
}

.plan-form-error {
  text-transform: capitalize;
  color: var(--Red);
  margin-bottom: 15px;
}

.calendar-tile-content {
  width: 100%;
}

.plan-brand-name {
  background-color: var(--LViolet);
  border-left: 5px solid var(--violet);
  margin: 2px 0px;
}

.plan-brand-name p {
  font-size: 12px;
  color: var(--violet);
  margin: unset;
  padding: unset;
}
