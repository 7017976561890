.meeting-card {
  box-shadow: 1px 1px 10px 5px #e0e0e98a;
  overflow: hidden;
  border-radius: 10px;
  border: 1px solid var(--Grey);
  background-color: #fafafa;
  flex-direction: row;
  padding: 10px 20px;
}

.date-time-wrapper {
  width: 35%;
  column-gap: 20px;
}

.meeting-date-wrapper {
  border-right: 1px solid var(--Grey);
  padding-right: 20px;
}

.day {
  font-size: 18px;
  font-weight: 500;
  text-transform: uppercase;
}

.date,
.month {
  font-size: 24px;
  font-weight: 600;
}

.meeting-agenda-btns-wrapper {
  width: 55%;
}

.join-btn button,
.not-attending-btn button {
  background: transparent;
  border: unset;
  color: var(--Red);
  font-weight: 500;
  font-size: 14px;
  text-decoration: underline;
  padding: unset;
}

.not-attending-btn button {
  color: var(--black);
}

.edit-delete-btns-wrapper {
  width: 10%;
  justify-content: end;
}

.edit-delete-btns-wrapper a svg {
  color: var(--black);
  font-size: 22px;
}

/* other  */

.mc-title-options-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
}

.mc-title h5 {
  color: var(--white);
  text-transform: capitalize;
  font-weight: 400;
}

.mc-options svg {
  color: var(--white);
  font-size: 20px;
}

.meeting-details-wrapper {
  padding: 20px;
}

.meeting-date-time p {
  font-weight: 500;
}

.meeting-catd-btn {
  text-align: center;
}

.mc-btn {
  border-radius: 10px;
  color: var(--white);
  border: unset;
  margin-top: 20px;
  font-size: 16px;
  font-weight: 200;
  padding: 10px 20px;
  border-radius: 10px;
}

.cancel-na-meeting-btns-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 15px;
}

.cancel-btn a,
.not-attending-btn a {
  text-decoration: underline !important;
  color: var(--DBlue);
}

@media (max-width: 767px) {
  .meeting-card {
    flex-direction: column;
    padding: 15px;
  }

  .meeting-agenda-btns-wrapper,
  .meeting-agenda-btns-wrapper,
  .edit-delete-btns-wrapper {
    width: 100% !important;
  }

  .meeting-date-wrapper {
    padding-right: 10px;
  }

  .date-time-wrapper {
    width: 100%;
    column-gap: 10px;
    margin-bottom: 20px;
  }

  .day {
    font-size: 16px;
  }

  .date,
  .month {
    font-size: 18px;
  }

  .meeting-card-time-wrapper {
    flex-direction: column;
  }

  .meeting-card-time-wrapper svg {
    margin-bottom: 3px;
  }

  .meeting-date-wrapper p,
  .meeting-card-time-wrapper {
    line-height: 1.2;
  }

  .meeting-card-time-wrapper,
  .meeting-card-time-wrapper p {
    font-size: 14px;
  }

  .edit-delete-btns-wrapper {
    padding-top: 10px;
  }

  .join-btn button,
  .not-attending-btn button {
    font-size: 12px;
  }

  .edit-delete-btns-wrapper a svg {
    font-size: 18px;
  }

  .mc-title-options-wrapper {
    padding: 5px 10px;
  }

  .mc-title h5 {
    font-size: 12px;
  }

  .mc-options svg {
    font-size: 16px;
  }

  .meeting-details-wrapper {
    padding: 15px;
  }

  .mc-client-name h6,
  .mc-project-name h6 {
    font-size: 16px;
  }

  .meeting-date-time p {
    font-size: 14px;
    padding: 2px 5px;
  }

  .mc-btn {
    padding: 5px 10px;
    font-size: 14px;
    font-weight: 500;
  }

  .cancel-btn a,
  .not-attending-btn a {
    font-size: 12px;
  }
}

.meeting-date-time {
  width: 70%;
  text-align: center;
  border-radius: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  column-gap: 5px;
}

.disabled {
  opacity: 0.5 !important;
}
