.new-btn-wrapper {
  background-color: var(--violet);
  border-radius: 10px;
  padding: 10px;
  border: unset;
}

.new-btn-wrapper p,
.new-btn-wrapper svg {
  color: var(--white);
}

.new-btn-wrapper svg:first-child {
  font-size: 55px;
  background-color: rgba(255, 255, 255, 0.45);
  padding: 5px;
  border-radius: 10px;
}

.new-btn-wrapper svg:last-child {
  font-size: 20px;
}

@media (max-width: 767px) {
  .new-btn-wrapper {
    width: 80%;
    margin: 0 auto;
    margin-top: 1rem !important;
    padding: 10px;
  }

  .new-btn-wrapper svg:first-child {
    font-size: 35px;
  }

  .new-btn-wrapper svg:last-child {
    font-size: 16px;
  }
}
