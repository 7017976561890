@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");

:root {
  --violet: #9681f9;
  --LViolet: #eae6fe;
  --RBlue: #4461f2;
  --lRBlue: #4461f28a;
  --DBlue: #1a1f37;
  --NBlue: #1d233f;
  --LBlue: #242a45;
  --Pink: #f5719a;
  --Green: #a2cd48;
  --lGreen: #ebfcc5;
  --LGreen: #a2cd48;
  --Orange: #feb056;
  --LOrange: #ffe8cc;
  --Red: #ee534f;
  --LRed: #ffe3df;
  --SkyBlue: #4178c8;
  --LSkyBlue: #4bcaed;
  --Turquoise: #25d2c5;
  --DAqua: #f2d722;
  --lDAqua: #f2d62293;
  --Grey: #a8a8a8;
  --LGrey: #e0e0e9;
  --black: #000000;
  --lBlack: #00000049;
  --white: #ffffff;
  --ff: "Montserrat", sans-serif;
  --pSize: 14px;
  --bHsize: 60px;
  --mbHsize: 26px;
  --hSize: 40px;
  --mhSize: 20px;
  --sHsize: 25px;
  --f20: 20px;
  --f15: 14px;
  --f45: 45px;
}

body {
  padding: 0px;
  margin: 0px;
  box-sizing: border-box;
  overflow-x: hidden;
}
h6,
.h6,
h5,
.h5,
h4,
.h4,
h3,
.h3,
h2,
.h2,
h1,
.h1 {
  font-weight: 500 !important;
  line-height: unset !important;
}

img {
  user-select: none;
}

h1 {
  color: red;
}

* {
  font-family: "Montserrat", sans-serif;
}

li {
  font-size: var(--pSize);
}

a {
  text-decoration: none !important;
  cursor: pointer;
}

img {
  width: 100%;
}

.pt-100 {
  padding-top: 100px;
}

.pt-50 {
  padding-top: 50px;
}

.pt-25 {
  padding-top: 25px;
}

.pt-30 {
  padding-top: 30px;
}

.pt-10 {
  padding-top: 10px;
}

.pt-20 {
  padding-top: 20px;
}

.pb-20 {
  padding-bottom: 20px;
}

.pb-30 {
  padding-bottom: 30px;
}

.pb-50 {
  padding-bottom: 50px;
}

.pb-100 {
  padding-bottom: 100px;
}

.p-0 {
  padding: 0;
}

.pt-0 {
  padding-top: 0px;
}

.pb-0 {
  padding-bottom: 0px;
}

.p-rel {
  position: relative;
}

.custom-padding {
  padding: 2% 3%;
}

.cp-card-section {
  padding: 2% 1%;
}

.mtb-20 {
  margin: 20px 0px;
}

.mb-50 {
  margin-bottom: 50px;
}

.mb-100 {
  margin-bottom: 100px !important;
}

.mt-0 {
  margin-top: 0px;
}

.m-0 {
  margin: 0px;
}

.mr-10 {
  margin-right: 10px;
}

.mr-5 {
  margin-right: 5px;
}

.ff {
  font-family: "Poppins";
}

.fw-400 {
  font-weight: 400;
}

.fw-500 {
  font-weight: 500;
}

.fw-600 {
  font-weight: 600;
}

.fw-700 {
  font-weight: 700;
}

.fw-800 {
  font-weight: 700;
}

.pSize {
  font-size: var(--pSize);
}

.f-20 {
  font-size: var(--f20);
}

.f-15 {
  font-size: 15px;
}

.f-18 {
  font-size: 18px;
}

.text-align-last {
  text-align: end;
}

h6,
.h6,
h5,
.h5,
h4,
.h4,
h3,
.h3,
h2,
.h2,
h1,
.h1 {
  font-weight: 500 !important;
  line-height: unset !important;
}

.common-btn {
  background-color: var(--violet) !important;
  border: unset !important;
  color: var(--white) !important;
  text-transform: capitalize !important;
  border-radius: 10px !important;
  padding: 10px 40px !important;
}

.bg-red {
  background-color: var(--Red) !important;
}

.bg-orange {
  background-color: var(--Orange) !important;
}

.bg-white {
  background-color: var(--white) !important;
}

.bg-grey {
  background-color: var(--LViolet) !important;
}

.not-clickable {
  opacity: 0.5;
}

@media (max-width: 767px) {
  .common-btn {
    padding: 5px 20px !important;
    font-size: 12px !important;
  }

  html {
    overflow-x: hidden;
  }

  h2 {
    font-size: 20px !important;
  }
}