.sm-kpi-table-wrapper,
.social-media-cards-container {
  
}

.social-media-cards-container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 50px;
}

.social-media-card-wrapper {
  padding: 20px 15px;
  text-align: center;
  border-radius: 10px;
  color: var(--white);
}

.icon {
  margin-bottom: 10px;
}

.details p {
  color: var(--black);
}

.sm-no-graph-wrapper {
  
  margin-bottom: 50px;
}



.csv-box-wrapper{
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #0000004a;
  display: flex;
  justify-content: center;
  align-items: center;
}

.csv-box-container{
  /* padding: 20px; */
  width: 40%;
  height: 250px;
  display: flex;
  flex-direction: column;
  background: #fff;
  border-radius: 10px;
  overflow: hidden;
}

.cvs-box-header{
  background: #cccccc80;
  padding: 20px;
}

.cvs-box-bottom{
  padding: 10px;
  border-top: 1px solid #0000001f;
}

.cvs-box-middle{
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.cvs-box-middle button{
  padding: 5px 15px;
}

.csv-box-selected-file{
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 5px;
}