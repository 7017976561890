.calender-wrapper {
  
}

.calender-wrapper .react-calendar {
  width: auto;
  border: unset;
}

.calender-wrapper
  .react-calendar
  .react-calendar__month-view
  .react-calendar__month-view__weekdays
  .react-calendar__month-view__weekdays__weekday {
  border: 1px solid #e0e1e2;
}

.calender-wrapper .react-calendar .react-calendar__month-view {
  border: 1px solid #e0e1e2;
}

.calender-wrapper .react-calendar .react-calendar__month-view__days__day {
  border: 1px solid #e0e1e2;
}

.calender-wrapper
  .react-calendar
  .react-calendar__month-view__days
  .react-calendar__tile {
  min-height: 90px;
  max-height: fit-content;
  font-size: 20px;
  display: flex;
  justify-content: space-between;
  padding: unset;
  flex-direction: column;
  align-items: flex-end;
}

.calender-wrapper
  .react-calendar
  .react-calendar__month-view
  .react-calendar__month-view__weekdays
  .react-calendar__month-view__weekdays__weekday
  abbr {
  text-decoration: none;
}

.calender-wrapper
  .react-calendar
  .react-calendar__navigation
  .react-calendar__navigation__label
  .react-calendar__navigation__label__labelText {
  font-size: 26px;
  font-weight: 500;
}

.calender-wrapper
  .react-calendar
  .react-calendar__navigation
  .react-calendar__navigation__arrow {
  font-size: 45px;
  padding-bottom: 8px;
}

.calender-wrapper .react-calendar .react-calendar__tile--active {
  background: var(--NBlue);
}

.calender-wrapper .react-calendar .react-calendar__tile--active:enabled:hover,
.calender-wrapper .react-calendar .react-calendar__tile--active:enabled:focus {
  background: var(--NBlue);
}

.taskhead-content,
.add-plan-btn {
  padding-top: 20px;
}

.add-plan-btn {
  text-align: center;
}

.add-plan-btn button {
  background-color: var(--violet);
  border: unset;
  color: var(--white);
  text-transform: capitalize;
  border-radius: 10px;
  padding: 10px 40px;
  animation: zoom-in-zoom-out 1s ease forwards;
}

@keyframes zoom-in-zoom-out {
  0% {
    transform: scale(1.2, 1.2);
  }
  /* 50% {
    transform: scale(1.5, 1.5);
  } */
  100% {
    transform: scale(1, 1);
  }
}

.plan-list li strong {
  text-transform: capitalize;
}

.calender-wrapper
  .react-calendar
  .react-calendar__month-view__days
  .react-calendar__tile
  abbr {
  padding: 10px 10px 0px 0px;
}
