.dept-card {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.dept-card .card-body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}

.dept-card .card-body p {
  font-size: 22px;
  font-weight: 500;
  line-height: 1;
}

.dept-card .card-body .card-title {
  margin: 0px;
}

/* Department details  */
.department-overview {
  background-color: var(--Green);
}

.dept-detail {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.dept-name-wrapper h4 {
  /* text-align: center; */
  text-transform: uppercase;
  color: var(--white);
  font-weight: 600;
  margin-bottom: 0px;
}

.dept-detail p {
  margin-bottom: 0px;
}

.dept-detail p:last-child {
  color: var(--white);
  font-weight: 600;
}

@media (max-width: 767px) {
  .department-cards {
    padding-bottom: 20px;
  }

  .dept-card {
    height: 40px;
  }

  .dept-name-wrapper {
    text-align: center;
    padding: 10px 0px;
  }

  .dept-detail p {
    font-size: 12px;
  }

  .dept-details-container {
    padding-bottom: 10px;
  }

  .department-employees {
    margin-bottom: 50px;
  }
}
