.content-register-form {
  padding: 20px 0px;
}

.signup-head {
  padding-bottom: 20px;
}

.signup-head h2 {
  font-size: 60px;
  font-weight: 300;
}

.signup-pg-txt {
  margin-bottom: 50px;
}

.signup-pg-txt p {
  font-weight: 200;
  letter-spacing: 0.5px;
}

.reg-form-head h2 {
  color: var(--violet);
  font-weight: 300;
}

.signup-form-wrapper input {
  height: 50px;
  width: 100%;
  padding: 5px 10px;
  margin-bottom: 5px;
  border: 1px solid var(--LGrey);
  box-shadow: 10px 10px 15px rgba(68, 97, 242, 0.1);
  border-radius: 10px;
}

.signup-form-wrapper input::placeholder {
  color: var(--LGrey);
  font-size: 14px;
}

.reg-form-btn {
  width: 25%;
  height: 50px;
  background-color: var(--violet);
  border: unset;
  margin-top: 5px;
  border-radius: 10px;
}

.existing-customer-signin {
  text-align: center;
  padding-top: 10px;
}

.existing-customer-signin p a {
  color: var(--blue);
  text-decoration: underline;
}

.signup-pg-img {
  overflow: hidden;
}

/* .signup-pg-img img {
  width: 100%;
} */

/* new design  */
.sf-email {
  width: 40%;
  margin-right: 10px;
}

.sf-phone {
  width: 60%;
}

.PhoneInputCountry {
  height: 50px;
  width: 15%;
  padding: 5px 10px;
  margin-bottom: 15px;
  border: 1px solid var(--LGrey);
  box-shadow: 10px 10px 15px rgba(68, 97, 242, 0.1);
  border-radius: 10px;
}

.PhoneInputCountrySelectArrow {
  margin-left: 10px !important;
}

.existing-customer-signin p a {
  text-decoration: underline !important;
}

@media (max-width: 767px) {
  .content-register-form {
    padding-top: 20px;
  }

  .signup-head {
    padding-bottom: 5px;
  }

  .signup-pg-txt {
    margin-bottom: 20px;
  }

  .signup-pg-img img {
    height: auto;
    width: 100%;
    margin-top: unset;
  }

  .signup-pg-img {
    padding-bottom: 20px;
  }

  .signup-form-wrapper .form-label {
    padding-bottom: 5px;
  }

  .signup-form-wrapper input,
  .PhoneInputCountry,
  .reg-form-btn {
    height: 40px;
  }

  .sf-email-phone {
    flex-direction: column;
  }

  .sf-email,
  .sf-phone {
    width: 100%;
  }

  .PhoneInputCountry {
    width: 20%;
  }

  .pCode-city-state-wrapper {
    flex-direction: column;
  }

  .sf-leaves,
  .sf-wfh,
  .sf-holiday-count,
  .sf-choose-holidays {
    margin-bottom: unset !important;
  }

  .working-days-wrapper,
  .leaves-wfh-container,
  .holiday-count-choose-holidays {
    flex-direction: column;
    align-items: unset !important;
  }

  .working-days-wrapper select {
    height: 40px;
  }
}
