.emp-card {
  margin-top: 20px;
  box-shadow: 1px 1px 15px 5px #e0e0e98a;
  overflow: hidden;
  border-radius: 10px;
  height: auto;
  max-height: 270px;
  border: unset;
}

.cc-uniqueNo-wrapper h6 {
  color: var(--white);
}

.ec-uniqueNo-wrapper,
.emp-status {
  padding: 5px 0px;
}

.emp-card-content-wrapper {
  padding: 20px 30px;
  column-gap: 20px;
}

.ec-uniqueNo-wrapper h6 {
  color: var(--white);
  font-weight: 300;
  margin-bottom: unset;
}

.emp-img img {
  height: 100px;
  width: auto;
}

.emp-img svg {
  font-size: 50px;
}

.emp-name p {
  font-weight: 500;
  margin-bottom: 0px;
}

.emp-team {
  margin-top: 5px;
  border-radius: 20px;
  padding: 1px 20px;
  text-align: center;
  width: fit-content;
}

.emp-team p {
  font-size: 12px;
  font-weight: 600;
}

.emp-dept {
  background-color: var(--lGreen);
  margin-top: 5px;
  width: fit-content;
  border-radius: 10px;
  text-align: center;
}

.emp-dept p {
  font-size: 12px;
  font-weight: 600;
  color: var(--Green);
  padding: 1px 20px;
}

.emp-email a {
  text-decoration: none !important;
  color: var(--black);
  font-weight: 300;
  word-break: break-all;
}

.emp-email a p,
.emp-phone a p {
  font-size: 12px;
  margin-bottom: unset;
}

.rm-img img {
  height: 20px;
  width: auto;
}

.ec-uniqueNo-wrapper h6,
.emp-status h6 {
  font-size: 12px;
  color: var(--white);
  font-weight: 500;
  text-transform: capitalize;
}

.ec-uniqueNo-wrapper h6 span {
  text-transform: capitalize;
}

.emp-card-wrapper .action-btn-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 20px;
}

.ec-uniqueNo-wrapper svg {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  color: #fff;
  cursor: pointer;
}

.dropdown-optio-wrapper {
  display: flex;
  flex-direction: column;
  position: absolute;
  right: 0;
  top: 100%;
  background: #fff;
  box-shadow: 0px 0px 25px -5px #ccc;
}

.dropdown-optio-wrapper ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.dropdown-optio-wrapper ul li {
  border-bottom: 1px solid #ccc;
  padding: 5px 15px;
  color: #000;
}

.dropdown-optio-wrapper ul li:hover {
  background-color: #ccc6;
}

/* 
.dropdown-optio-wrapper ul li:last-child{
  border-bottom: none;
} */

.employee-working-status {
  display: flex;
  align-items: center;
  column-gap: 10px;
  padding: 5px 0px 10px 10px;
}

.employee-working-status p {
  margin-bottom: 0px;
}

.bullet {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 8px;
  background-color: var(--Green);
  margin: 0;
  position: absolute;
  right: 0;
  top: 30%;
}

/* .blink {
  animation: blink 1s infinite;
}

@keyframes blink {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
} */

/* common css for cards  */
@media (max-width: 767px) {
  .ec-uniqueNo-wrapper,
  .emp-status,
  .cc-uniqueNo-wrapper {
    padding: 5px 0px;
  }

  .ec-uniqueNo-wrapper h6,
  .emp-status h6 {
    font-size: 12px;
  }

  .ec-uniqueNo-wrapper svg {
    height: 12px;
  }

  .emp-card {
    min-height: fit-content;
  }

  .emp-card-content-wrapper {
    padding: 10px 25px;
  }

  .emp-img svg {
    font-size: 30px;
  }

  .bullet {
    height: 5px;
    width: 5px;
    bottom: 5px;
  }

  .emp-name p {
    font-size: 14px;
  }

  .emp-team {
    margin-top: 5px;
  }

  .emp-team p {
    font-size: 10px;
  }

  .emp-email a,
  .emp-phone a {
    font-size: 12px;
  }
}
