.tt-desc {
  width: 30%;
}

.att-filters-wrapper {
  column-gap: 20px;
}

.att-filters p {
  padding: unset;
  margin: unset;
}

@media (max-width: 767px) {
  .att-dropdown-btns .btn-edit {
    padding: 5px 10px;
  }

  .att-filters-wrapper {
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .att-filters-wrapper .manage-project-dropdown:last-child {
    margin-top: 5px;
  }
}
