.tasks-table-wrapper {
}

.tasks-table {
  width: 100%;
  border-radius: 10px;
  height: 100%;
  overflow-y: scroll;
}

/* .tasks-table tr {
  padding: 5% 0%;
} */

.tasks-table tr th {
  text-transform: uppercase;
  padding: 10px;
  font-size: 14px;
  font-weight: 500;
}

.tasks-table tr td {
  padding: 10px 0px;
  color: var(--white);
  text-align: center;
  font-size: 14px;
}

.tasks-table tr th {
  color: var(--white);
  text-align: center;
}

.tasks-table tr:nth-child(even) {
  background-color: var(--DBlue);
}

.tasks-table thead tr {
  background-color: var(--DBlue) !important;
}

.tasks-table tr:nth-child(odd) {
  background-color: var(--LBlue);
}

/* .tasks-table thead tr:first-child th:first-child {
  border-top-left-radius: 10px;
}

.tasks-table thead tr:first-child th:last-child {
  border-top-right-radius: 10px;
}

.tasks-table tbody tr:last-child td:first-child {
  border-bottom-left-radius: 10px;
}

.tasks-table tbody tr:last-child td:last-child {
  border-bottom-right-radius: 10px;
} */

@media (max-width: 767px) {
  .tasks-table tr th {
    font-size: 10px;
    padding: 5px;
  }

  .task-table-container {
    overflow-x: scroll;
    padding-top: 20px;
  }

  .tasks-table tr td {
    padding: 5px !important;
    font-size: 10px;
  }

  .customTt thead {
    top: unset !important;
  }
}
