.notif-icon {
  font-size: 24px;
}

.notifications-count {
  position: absolute;
  top: -9px;
  right: -7px;
  background-color: var(--DBlue);
  border-radius: 50%;
  height: 15px;
  width: 15px;
  color: var(--white);
  margin-bottom: unset;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 10px;
  padding: 10px;
}

.notif-icon-wrapper{
  padding: 0;
  background: transparent;
  border: none;
  outline: 0;
}

.notification-popup {
  position: absolute;
  top: 50px;
  right: 5%;
  background-color: var(--white);
  border-radius: 10px;
  width: 400px;
  z-index: 9999;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  padding: 20px;
}

.notification-btn-container {
  height: 60vh;
  overflow-y: scroll;
}

.show-more-btn {
  color: var(--DBlue);
  font-size: 12px;
  font-weight: 500;
  text-decoration: underline;
}

@media (max-width: 767px) {
  .notif-icon {
    font-size: 20px;
  }

  .notifications-count {
    padding: 5px;
  }
}
