.emp-profile-wrapper {
}

.profile-pic-container {
  box-shadow: 1px 1px 15px 5px #e0e0e98a;
  text-align: center;
  border-radius: 10px;
  overflow: hidden;
  margin-top: 50px;
}

.bg-wrapper {
  background-color: #feb05685;
  height: 130px;
  width: 100%;
}

.profile-pic-wrapper {
  background-color: var(--white);
  height: 130px;
  width: 100%;
}

.pfp-change {
  position: absolute;
  top: 31%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.pfp-change.change-pfp-position {
  top: 27%;
}

.change-profile-pic p {
  padding-top: 5px;
  font-weight: 600;
}

.profile-pic img {
  height: 100px;
  width: auto;
}

.basic-emp-info-card-container {
  margin: 50px 0px 100px 0px;
  background-color: var(--white);
  box-shadow: 1px 1px 15px 5px #e0e0e98a;
  border-radius: 10px;
  overflow: hidden;
  padding: 2% 3%;
}

.head-edit-btn {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.btn-edit,
.btn-save {
  background-color: var(--violet);
  color: var(--white);
  border: unset;
  padding: 10px 30px;
  border-radius: 10px;
}

.btn-edit svg,
.btn-save svg {
  margin-top: -5px;
}

.emp-info-label p {
  color: var(--Grey);
  margin-bottom: 0px;
}

.emp-info input {
  height: 50px;
  width: 90%;
  padding: 5px 10px;
  margin: 5px 0px 15px 0px;
  border: unset;
  box-shadow: 2px 0px 15px rgba(68, 97, 242, 0.1);
  border-radius: 10px;
}

.emp-info-wrapper {
  margin-bottom: 10px;
}

.emp-info p {
  text-transform: capitalize;
}

.profile-pic-label {
  font-size: 14px;
  text-decoration: underline;
  color: var(--SkyBlue);
}

@media (max-width: 767px) {
  .btn-edit,
  .btn-save {
    border: unset;
    padding: 5px 10px !important;
    font-size: 12px;
  }
}
