.main-footer {
  border-radius: 10px 10px 0px 0px;
  box-shadow: 0px 0px 15px rgba(68, 97, 242, 0.1);
  height: 50px;
  width: 100%;
  position: fixed;
  bottom: 0px;
  z-index: 9999;
  background-color: var(--white);
}

.footer-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 0px;
}

.copyright-content p {
  margin-bottom: 0px;
  font-size: 14px;
}

.policy-pages a {
  color: black;
  font-weight: 400;
  margin: 0 10px;
  font-size: 14px;
}

@media only screen and (max-width: 767px) {
  .main-footer {
    height: 55px;
    position: fixed;
  }

  .footer-wrapper {
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 5px 0px;
  }

  .copyright-content p,
  .policy-pages a {
    font-size: 10px;
  }

  .copyright-content {
    padding-top: 5px;
  }
}
