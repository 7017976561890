.leave-form-type {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.leave-form .container {
  padding: 0px;
}

.btn-apply {
  margin-top: 20px;
}

.reason-label {
  padding-top: 20px;
}

.working-days-wrapper .form-label {
  margin-bottom: unset;
}

.sf-leaves,
.sf-wfh {
  width: 90%;
}

.working-days-wrapper select {
  width: 90%;
}

.sf-holiday-count,
.sf-choose-holidays {
  width: 100%;
}

.working-days-wrapper select {
  height: 50px;
  padding: 5px 10px;
  border: 1px solid var(--LGrey);
  box-shadow: 10px 10px 15px rgba(68, 97, 242, 0.1);
  border-radius: 10px;
}