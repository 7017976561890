.nt-table tr td {
  padding: 20px;
}

.nt-table tr td input,
.nt-table tr td select {
  margin-bottom: unset !important;
  height: 40px !important;
  color: #000
}

.tt-col-width {
  width: 40%;
}

.scrollable-summary {
  max-height: 3em;
  overflow-y: auto !important;
  display: inline-block;
  text-overflow: ellipsis;
  overflow: hidden;
  position: relative;
  padding: 0 8px;
}

.scrollable-summary::after {
  content: attr(data-summary);
  display: block;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 100%;
}

.scrollable-summary[data-summary]:hover::after {
  white-space: normal;
  overflow: auto;
}

.scrollable-summary::-webkit-scrollbar {
  width: 5px;
}

.scrollable-summary::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 4px;
}

.scrollable-summary::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}

.scrollable-summary::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.scrollable-summary {
  scrollbar-width: thin;
  scrollbar-color: #888 #f1f1f1;
}
