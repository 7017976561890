.error404 {
  padding-top: 80px;
}

.error-pg-content-container h3 {
  padding-top: 30px;
  color: var(--violet);
  text-transform: uppercase;
  font-weight: 600;
}

.error-pg-main-img img {
  height: 200px;
  width: auto;
}

.error-pg-content-container h1 {
  color: var(--violet);
  font-weight: 700;
  font-size: 45px;
}

.error-pg-content-container p {
  padding: 0px 30%;
}

@media (max-width: 767px) {
  .error404 {
    padding-top: 60px;
  }

  .error-pg-main-img img {
    height: auto;
    width: 100%;
  }

  .error-pg-content-container p {
    padding: 0px 5%;
  }
}
