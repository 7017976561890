.client-card {
  text-align: center;
  margin-top: 20px;
  box-shadow: 1px 1px 15px 5px #e0e0e98a;
  overflow: hidden;
  border-radius: 10px;
  border: unset;
}

.cc-uniqueNo-wrapper,
.client-status {
  padding: 10px 0px;
}

.client-card-content-wrapper {
  padding: 20px 30px;
}

.brand-logo {
  padding-bottom: 15px;
}

.brand-logo img {
  height: 40px;
  width: auto;
}

.brand-name h6,
.client-poc p {
  font-weight: 600;
  text-transform: capitalize;
}

.client-poc {
  border-radius: 20px;
  padding: 0px 10px;
  margin: 10px 0px;
}

.client-poc select {
  border: unset;
  font-weight: 400;
  font-size: 14px;
  padding: 5px 0px;
}

.client-name {
  border-radius: 20px;
  margin-bottom: 5px;
}

.client-name p {
  font-weight: 600;
  text-transform: capitalize;
}

.client-status h6 {
  color: var(--white);
  text-transform: capitalize;
}

.client-email a {
  color: var(--black);
}

.cc-uniqueNo-wrapper svg {
  color: var(--white);
  cursor: pointer;
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 24px;
}

@media (max-width: 767px) {
  .cc-uniqueNo-wrapper,
  .client-status {
    padding: 5px 0px;
  }

  .cc-uniqueNo-wrapper h6,
  .client-status h6 {
    font-size: 12px;
  }

  .cc-uniqueNo-wrapper svg {
    height: 12px;
  }

  .client-card-content-wrapper {
    padding: 10px 25px;
  }

  .client-name p {
    font-size: 14px;
  }

  .client-email a,
  .client-phone a {
    font-size: 12px;
  }
}
