.roles-table-button-wrapper{
    display: flex;
    justify-content: end;
}

.table-action-icons-wrapper{
    display: flex;
    align-items: center;
    column-gap: 10px;
    justify-content: center;
}

.table-action-icons-wrapper svg{
    font-size: 18px;
    cursor: pointer;
}