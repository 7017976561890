.OvCardCol {
  width: 20% !important;
}

@media (max-width: 767px) {
  .OvCardCol {
    width: 50% !important;
    padding: 0 5px;
  }

  .ovc-title {
    font-size: 28px !important;
  }

  .ovc-text {
    font-size: 14px;
    line-height: 1.2;
  }
}
