.welcome-msg-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@media (max-width: 767px) {
  .welcome-msg-wrapper {
    padding: 10px 0px !important;
  }

  .greet-emp-name h3 {
    font-size: 16px;
  }
}
