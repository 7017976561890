.error-msg {
  color: #ff0000;
  margin-bottom: 10px;
}

.action-btn-wrapper {
  display: flex;
  align-items: center;
  justify-content: end;
  padding-top: 20px;
}

#main-content-wrapper {
  position: relative;
}

html,
body,
* {
  margin: 0;
}

@media (max-width: 767px) {
  #main-content-wrapper {
    margin-bottom: 50px !important;
  }

  .action-btn-wrapper {
    padding-top: 0px;
  }
}

.clickable{
  cursor: pointer;
}